import React, { useContext, useEffect, useCallback, useState } from "react";
//import Calendar from "@ericz1803/react-google-calendar";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useLocation } from "react-router-dom";
import CalendarEditor from "../../components/CalendarEditor";
import "./Dashboard.css";
import moment from "moment";
import { emailService } from "../../services";
import { calendarService } from "../../services/calendar.service";
import TodayIcon from "@mui/icons-material/Today";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import googleCalendarService from "../../services/google-calendar.service";
import { AuthContext } from "../../context/AuthContext";
import useWindowSize from "../../hooks";
import { tenantService } from "../../services/tenant.service";
import { useMemo } from "react";
import ThreeDayWeek from "./ThreeDayWeek";
import ThreeDayWorkWeek from "./ThreeDayWorkWeek";
import Week from "react-big-calendar/lib/Week";
import WorkWeek from "react-big-calendar/lib/WorkWeek";
import LinearProgress from "@mui/material/LinearProgress";

const localizer = momentLocalizer(moment);

const Appointments = (props) => {
  const size = useWindowSize();
  const { user, userData } = useContext(AuthContext);
  const location = useLocation();
  const [events, setEvents] = React.useState([]);
  const [patient, setPatient] = React.useState(null);
  const [showCalendarEditor, setShowCalendarEditor] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [lastCalendarDate, setLastCalendarDate] = React.useState(moment());
  const [calendarView, setCalendarView] = React.useState(Views.WORK_WEEK);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCalendarEvents = useCallback(async () => {
    try {
      setLoading(true);
      const calendarList = await calendarService.getCalendars();
      let calendarEvents = [];

      console.log(
        "Get calendar range",
        lastCalendarDate
          .clone()
          .startOf("month")
          .add(-1, "days")
          .startOf("month")
          .toISOString(),
        lastCalendarDate
          .clone()
          .endOf("month")
          .add(1, "days")
          .endOf("month")
          .toISOString()
      );
      for (let i = 0; i < calendarList.length; i++) {
        try {
          const calendar = calendarList[i];
          const response = await googleCalendarService.getEvents(calendar.id, {
            timeMin: lastCalendarDate
              .clone()
              .startOf("month")
              .add(-1, "days")
              .startOf("month")
              .toISOString(),
            timeMax: lastCalendarDate
              .clone()
              .endOf("month")
              .add(1, "days")
              .endOf("month")
              .toISOString(),
            showDeleted: false,
            maxResults: 1000,
            orderBy: "updated",
          });

          calendarEvents = calendarEvents.concat(
            response.data.items.map((i) => ({
              ...i,
              color: (calendar && calendar.color) || "#fff",
              backgroundColor:
                (calendar && calendar.backgroundColor) || "#7a3d79!important",
              calendarId: calendar.id,
              fontSize: calendar.fontSize,
            }))
          );
        } catch (error) {
          console.error("Error while loading calendar:", error);
        }
      }

      console.log(
        "Got calendar events",
        calendarEvents,
        moment(lastCalendarDate.clone()).format("L")
      );
      setEvents(
        calendarEvents.map((e) => ({
          id: e.id,
          title: e.summary,
          end:
            (e.end &&
              ((e.end.dateTime && new Date(e.end.dateTime)) ||
                new Date(e.end.date))) ||
            null,
          start:
            (e.start &&
              ((e.start.dateTime && new Date(e.start.dateTime)) ||
                new Date(e.start.date))) ||
            null,
          description: e.description,
          color: e.color,
          backgroundColor: e.backgroundColor,
          className: e.className,
          calendarId: e.calendarId,
          fontSize: e.fontSize || "medium",
          email: e?.extendedProperties?.private?.email,
        }))
      );
    } finally {
      setLoading(false);
    }
  }, [lastCalendarDate]);


  const { defaultDate, views } = useMemo(
    () => ({
      defaultDate: lastCalendarDate,
      views: {
        day: true,
        work_week: size.width < 576 ? ThreeDayWorkWeek : WorkWeek,
        week: size.width < 576 ? ThreeDayWeek : Week,
      },
    }),
    [size, lastCalendarDate]
  );

  useEffect(() => {
    if (location && location.state && location.state.patient) {
      setPatient(location.state.patient);
    }

    const fetchData = async () => {
      await getCalendarEvents();
    };

    fetchData();
  }, [getCalendarEvents, location]);

  useEffect(() => {
    console.log("Got patient info on calendar:", location);
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      await getCalendarEvents();
    };

    fetchData();
  }, [calendarView, lastCalendarDate, size, getCalendarEvents]);

 
  const toggleCalendarEditor = () => {
    setShowCalendarEditor(!showCalendarEditor);
  };

  const handleSelectSlot = async ({ start, end }) => {
    setSelectedEvent({
      start,
      end,
      title: `${(patient && patient.name) || ""}`,
      description: `Termin za ${(patient && patient.name) || ""}`,
    });
    toggleCalendarEditor();
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    toggleCalendarEditor();
  };

  const handleCalendarEditorSubmit = async (data) => {
    try {
      if (data.id) {
        if (data.calendarChanged && data.oldCalendarId) {
          await googleCalendarService.deleteEvent({
            eventId: data.id,
            calendarId: data.oldCalendarId,
          });
          await googleCalendarService.createEvent(data.calendarId, {
            ...data,
            email: data.email ?? "",
          });
        } else {
          await googleCalendarService.updateEvent({
            ...data,
            email: data.email ?? "",
          });
        }
      } else {
        await googleCalendarService.createEvent(data.calendarId, {
          ...data,
          email: data.email ?? "",
        });
      }

      console.log("Got data to insert event", data);
      //await ApiCalendar.createEvent(data, data.calendarId || 'primary');
      if (data?.email?.length) {
        const tenantData = await tenantService.getTenantInfo(user?.uid);
        const res = await emailService.sendAppointmentEmail(
          patient || { name: data.summary, email: data.email },
          data,
          {
            email: userData?.email ?? user.email ?? tenantData.email,
            name:
              userData?.displayName ??
              user.displayName ??
              tenantData.displayName,
            tel: userData?.phone ?? user.phoneNumber ?? tenantData.phoneNumber,
            mob:
              userData?.mobile ?? user.mobileNumber ?? tenantData.mobileNumber,
            address: userData?.address ?? user.address ?? tenantData.address,
          }
        );
        if (res && res.length && res[0].statusCode < 250) {
          console.log("Created event and sent mail sucessfully:", res);
        }
      }

      await getCalendarEvents();
      toggleCalendarEditor();
    } catch (error) {
      console.error("Error while creating calendar event:", error);
      alert("Dogodila se pogreška prilikom kreiranja termina!");
    }
  };

  const handleCalendarEditorDelete = async (data) => {
    //console.log("Got data to delete from calendar", data, patient || { name: data.summary || data.title, email: data.email})

    await googleCalendarService.deleteEvent({
      eventId: data.id,
      calendarId: data.calendarId,
    });
    try {
      //await ApiCalendar.createEvent(data, data.calendarId || 'primary');
      if (data?.email?.length) {
        const tenantData = await tenantService.getTenantInfo(user?.uid);
        const res = await emailService.sendCancellationEmail(
          patient || { name: data.summary || data.title, email: data.email },
          data,
          {
            email: userData?.email ?? user.email ?? tenantData.email,
            name:
              userData?.displayName ??
              user.displayName ??
              tenantData.displayName,
            tel: userData?.phone ?? user.phoneNumber ?? tenantData.phoneNumber,
            mob:
              userData?.mobile ?? user.mobileNumber ?? tenantData.mobileNumber,
            address: userData?.address ?? user.address ?? tenantData.address,
          }
        );
        if (res && res.length && res[0].statusCode < 250) {
          console.log("Created event and sent mail sucessfully:", res);
        }
      }

      await getCalendarEvents();
      toggleCalendarEditor();
    } catch (error) {
      console.error("Error while deleting appointment", error);
    }
  };

  const eventStyleGetter = (event) => {
    const style = {
      className: event.className,
      style: {
        backgroundColor: event.backgroundColor,
        color: event.color,
        fontSize: event.fontSize || "medium",
      },
    };

    return style;
  };

  const today = new Date();

  const handleNavigate = (date, view) => {
    if (moment(date).format("MMYYYY") !== lastCalendarDate.format("MMYYYY")) {
      setLastCalendarDate(moment(date));
    }

    setCalendarView(view);
  };

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 100;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        const forward = document.querySelector(
          ".rbc-toolbar .rbc-btn-group .calendar-forward"
        );
        forward?.parentElement?.click();
      } else {
        const back = document.querySelector(
          ".rbc-toolbar .rbc-btn-group .calendar-back"
        );
        back?.parentElement?.click();
      }
    }
  };

  return (
    <div
      className="calendar-widget"
      style={props.style || null}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div className="calendar-events">
        {(loading && <LinearProgress />) || null}

        <Calendar
          selectable
          view={calendarView}
          defaultView={Views.WORK_WEEK}
          views={views}
          onNavigate={(date, view) => handleNavigate(date, view)}
          onView={setCalendarView}
          min={
            new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)
          }
          step={15}
          timeslots={2}
          localizer={localizer}
          events={events}
          onSelectEvent={(event) => handleSelectEvent(event)}
          onSelectSlot={handleSelectSlot}
          startAccessor="start"
          endAccessor="end"
          className={`calendar-events-cal ${props.className || ""}`}
          eventPropGetter={eventStyleGetter}
          messages={{
            date: "Datum",
            time: "Vrijeme",
            event: "Događaj",
            allDay: "Cijeli dan",
            week: (
              <>
                <DateRangeIcon /> Tjedan
              </>
            ),
            work_week: "Radni tjedan",
            day: (
              <>
                <CalendarTodayIcon /> Dan
              </>
            ),
            month: "Mjesec",
            previous: (
              <>
                <ArrowBackIcon className="calendar-back" /> Natrag
              </>
            ),
            next: (
              <>
                <ArrowForwardIcon className="calendar-forward" /> Naprijed
              </>
            ),
            yesterday: "Jučer",
            tomorrow: "Sutra",
            today: (
              <>
                <TodayIcon /> Danas
              </>
            ),
            agenda: "Agenda",
            noEventsInRange:
              "Trenutno nemate zakazanih događaja u ovom datumskom rasponu.",
            showMore: (total) => `+${total} dodatno`,
          }}
        />
      </div>

      <CalendarEditor
        open={showCalendarEditor}
        patient={patient}
        event={selectedEvent}
        onClose={toggleCalendarEditor}
        onSubmit={handleCalendarEditorSubmit}
        onDelete={handleCalendarEditorDelete}
      />
    </div>
  );
};

export default Appointments;
